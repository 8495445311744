@import '../../variables.module';

.no__wrap{
    white-space: nowrap;
}

.product {
    display: flex;
    align-items: flex-start;

    &__image {
        width: 165px;
        margin-right: 15px;
        cursor: pointer;
    }

    &__description {
        min-width: 130px;
        max-width: 200px;
        width: 100%;

        &-row {
            margin: 10px 0;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: $textColorPrimary;
            display: flex;

            p {
                span {
                    white-space: nowrap;
                }
            }
        }

        &-divider {
            margin: 0 4px;
            width: 100%;
            height: 15px;
            border-bottom: 0.5px solid rgba(26, 26, 26, 0.2);
            line-height: 0;
        }
    }
}
